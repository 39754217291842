'use client';

import classNames from 'classnames';
import { Autoplay, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import ImageProxy from '@next-image-proxy';

import { useGetBannersQuery, useLaunchpadProjectsListQuery } from '@query';

import { LAUNCHPAD_PROJECT_STATUS } from '@api';

import ExternalLink from '@uikit/components/ExternalLink/ExternalLink';

import MainInfo from '@launchpad/components/pages/Main/ui/MainInfo/MainInfo';
import ProjectsSlider from '@launchpad/components/pages/Main/ui/ProjectsSlider/ProjectsSlider';
import NewsletterSubscribeWidget from '@launchpad/components/pages/Main/ui/widgets/NewsletterSubscribeWidget';

import Socials from '../../common/Socials/Socials';

const LIMIT_PROJECTS_LIST = 10;

const Page = () => {
  const activeProjectsQuery = useLaunchpadProjectsListQuery({
    searchParams: { limit: LIMIT_PROJECTS_LIST, status: LAUNCHPAD_PROJECT_STATUS.ACTIVE },
  });
  const upcomingProjectsQuery = useLaunchpadProjectsListQuery({
    searchParams: { limit: LIMIT_PROJECTS_LIST, status: LAUNCHPAD_PROJECT_STATUS.COMING },
  });
  const pastProjectsQuery = useLaunchpadProjectsListQuery({
    searchParams: { limit: LIMIT_PROJECTS_LIST, status: LAUNCHPAD_PROJECT_STATUS.PASSED },
  });
  const { data } = useGetBannersQuery();

  return (
    <div className="w-full flex-grow overflow-hidden">
      <main className="main-container py-4 sm:py-16">
        <MainInfo />

        <Swiper
          className={classNames('w-full static my-4 mb-6 md:my-12')}
          loop
          grabCursor
          spaceBetween={16}
          slidesPerView={1}
          modules={[Autoplay, Pagination]}
          autoplay={{
            delay: 5000,
            pauseOnMouseEnter: true,
          }}
          pagination={{
            el: '.launchpad-main-page-banners-swiper-pagination',
            type: 'bullets',
            clickable: true,
            bulletClass:
              'block w-3 h-3 rounded-full aspect-square bg-primary-300 hover:cursor-pointer',
            bulletActiveClass: 'bg-primary-700',
          }}
        >
          <div className="launchpad-main-page-banners-swiper-pagination w-full flex justify-center items-center gap-2 mt-4 sm:mt-6"></div>

          {data &&
            data.map((banner) => {
              return (
                <SwiperSlide key={banner.id} className={classNames('select-none w-full')}>
                  <ExternalLink href={banner?.link} className="block">
                    <ImageProxy
                      src={banner?.desktopImage}
                      alt={banner?.altText}
                      className={classNames(
                        'w-full object-cover flex justify-center items-center overflow-hidden rounded-lg aspect-[1920/540]',
                      )}
                      width={1920}
                      height={540}
                    />
                  </ExternalLink>
                </SwiperSlide>
              );
            })}
        </Swiper>

        <ProjectsSlider
          status={LAUNCHPAD_PROJECT_STATUS.ACTIVE}
          title="Active Sales"
          titleDecoration={<div className={' w-4 h-4 rounded-full bg-green-700'} />}
          className="my-4 md:my-12"
          projectsQuery={activeProjectsQuery}
          viewAllLabel="View All Active Sales"
          showViewAll
        />

        <ProjectsSlider
          status={LAUNCHPAD_PROJECT_STATUS.COMING}
          title="Upcoming Sales"
          titleDecoration={<div className={' w-4 h-4 rounded-full bg-secondary-700'} />}
          className="my-4 md:my-12"
          projectsQuery={upcomingProjectsQuery}
          viewAllLabel="View All Available Opportunities"
          showViewAll
        />

        <ProjectsSlider
          status={LAUNCHPAD_PROJECT_STATUS.PASSED}
          title="Past Sales"
          titleDecoration={<div className={' w-4 h-4 rounded-full bg-yellow-600'} />}
          className="my-4 md:my-12"
          projectsQuery={pastProjectsQuery}
        />

        <NewsletterSubscribeWidget className="my-4 md:my-12" />
        <Socials />
      </main>
    </div>
  );
};

export default Page;
