import { ComponentPropsWithoutRef } from 'react';

import useUniqueId from '@uikit/utils/hooks/useUniqueId';

const BinanceLabIcon = (props: ComponentPropsWithoutRef<'svg'>) => {
  const uid1 = useUniqueId();
  const uid2 = useUniqueId();

  return (
    <svg viewBox="0 0 83 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath={`url(#${uid1})`}>
        <g clipPath={`url(#${uid2})`}>
          <path
            d="M16.7256 6.82346L14.5094 8.994L10.4871 4.91573L6.52053 8.87198L4.22852 6.55478L10.3603 0.464355L16.7256 6.82346Z"
            fill="#FCC117"
          />
          <path
            d="M4.21173 14.8686L6.39946 12.6699L10.353 16.7728C11.9505 15.4201 13.1807 13.9617 14.5637 12.7133L16.7396 14.9425C14.6929 16.9723 12.568 19.0971 10.5011 21.1562L4.21173 14.8686Z"
            fill="#FCC117"
          />
          <path
            d="M31.4491 6.41032L32.0796 6.84677C32.3866 7.04857 32.6331 7.32899 32.7931 7.65985C32.9531 7.98954 33.0218 8.35677 32.991 8.72283C32.9602 9.08889 32.831 9.43852 32.6165 9.7377C32.4032 10.0357 32.1128 10.2715 31.7762 10.4194C31.3733 10.6177 30.9348 10.7361 30.4868 10.7678C29.0552 10.8112 27.6177 10.7843 26.1232 10.7843V2.56787C27.7705 2.55731 29.4309 2.35434 31.0474 2.80018C32.5726 3.21786 33.0064 4.91323 31.9552 6.09588C31.8485 6.21438 31.6696 6.2707 31.4491 6.41032ZM27.7717 9.28599C28.7376 9.22146 29.6513 9.42444 30.5639 9.15224C30.7594 9.11118 30.9336 9.00206 31.0545 8.84367C31.1754 8.68528 31.2358 8.48817 31.224 8.28989C31.2299 8.18312 31.2133 8.07636 31.1778 7.97546C31.141 7.87456 31.0853 7.78304 31.013 7.70326C30.9407 7.62465 30.853 7.56129 30.7559 7.51671C30.6587 7.47213 30.5532 7.44749 30.4465 7.44397C29.5624 7.3853 28.6689 7.42754 27.7705 7.42754V9.28599H27.7717ZM27.7504 5.88235C28.5314 5.92576 29.3076 5.99733 30.0732 5.8448C30.2735 5.81782 30.456 5.71927 30.5876 5.56557C30.7191 5.41304 30.789 5.21711 30.7843 5.01531C30.7973 4.82876 30.7404 4.64455 30.6267 4.49789C30.5129 4.35006 30.3482 4.25034 30.1645 4.21514C29.3716 4.02624 28.5456 4.01803 27.7504 4.19284V5.88235Z"
            fill="#FCC117"
          />
          <path
            d="M58.1539 2.57861H59.6472L63.3993 7.4594L63.6114 7.39018V2.59621H65.2729V10.7164H63.814L60.0181 5.78984L59.7929 5.85085C59.7029 6.63459 59.7633 7.42889 59.7538 8.21968C59.7432 9.00928 59.7538 9.84582 59.7538 10.7223H58.1575L58.1539 2.57861Z"
            fill="#FCC117"
          />
          <path
            d="M41.0197 5.78992V10.7129H39.3392V2.59277H40.8917C42.1894 4.17668 43.3473 5.89317 44.6877 7.463L44.8571 7.38204V2.60803H46.4701V10.7165H45.0586L41.2342 5.73478L41.0197 5.78992Z"
            fill="#FCC117"
          />
          <path
            d="M76.2648 10.7105V2.59033H82.399V4.16134H78.1101V5.8919H81.893V7.37491H78.1101V9.23571H82.3919V10.7046L76.2648 10.7105Z"
            fill="#FCC117"
          />
          <path
            d="M56.6203 10.7558H54.8498L54.0545 8.94781H50.5098L49.73 10.7394H48.0104C49.1967 7.96813 50.3404 5.26845 51.4923 2.56055H53.1266L56.6203 10.7558ZM51.1723 7.33691C51.9912 7.44368 52.6549 7.50586 53.3921 7.28998L52.3859 4.87423H52.1844L51.1723 7.33691Z"
            fill="#FCC117"
          />
          <path
            d="M74.5538 3.70374C74.504 3.81285 74.4435 3.9161 74.3748 4.01348C74.1141 4.30797 73.8427 4.5919 73.5547 4.90164C72.7832 4.39362 72.0212 3.87151 71.0577 4.05572C70.4687 4.16131 69.9413 4.47926 69.5727 4.94857C69.1816 5.46481 68.9766 6.09837 68.9908 6.74602C69.005 7.39366 69.2385 8.01666 69.6521 8.51647C70.5836 9.58766 71.7865 9.56889 73.6377 8.44491C73.8071 8.60095 74.0015 8.76169 74.1804 8.93416C74.3582 9.10663 74.4874 9.25563 74.632 9.41168C73.5618 11.093 70.5101 11.4567 68.7491 10.0206C66.8623 8.4848 66.6668 5.62203 68.2715 3.77648C69.7872 2.03418 73.3841 2.12217 74.5538 3.70374Z"
            fill="#FCC117"
          />
          <path
            d="M26.11 16.4783V15.7778H57.9921C58.011 15.989 58.0288 16.199 58.0549 16.4783H26.11Z"
            fill="#FCC117"
          />
          <path
            d="M72.7402 13.3347C73.757 13.3347 74.7039 13.3265 75.6579 13.3347C76.2955 13.3347 76.8122 13.5752 77.0801 14.1971C77.3479 14.8201 77.1618 15.3386 76.6937 15.7903C76.6309 15.8607 76.574 15.937 76.5242 16.0168C76.6013 16.0954 76.6842 16.1658 76.7719 16.2303C77.3503 16.5565 77.6193 17.0622 77.502 17.7016C77.3846 18.341 76.9296 18.8197 76.2493 18.8854C75.1092 19.0051 73.9608 19.0544 72.7378 19.1353L72.7402 13.3347ZM73.6871 18.2096C74.455 18.1756 75.1768 18.1756 75.8902 18.0993C76.0727 18.0759 76.241 17.9879 76.3631 17.8494C76.4851 17.711 76.5527 17.5338 76.5527 17.3496C76.5527 17.1654 76.4863 16.9871 76.3631 16.8498C76.241 16.7114 76.0727 16.6222 75.8902 16.5999C75.1531 16.5354 74.4076 16.5811 73.6788 16.5811L73.6871 18.2096ZM73.6871 15.7012C74.2761 15.727 74.8663 15.7223 75.4541 15.6848C75.8546 15.639 76.1983 15.3938 76.2185 14.9257C76.2386 14.4575 75.9495 14.183 75.5074 14.1548C74.9113 14.1161 74.3081 14.1443 73.6859 14.1443V15.7012H73.6871Z"
            fill="#FCC117"
          />
          <path d="M36.689 2.6001V10.7367H35.1045V2.6001H36.689Z" fill="#FCC117" />
          <path
            d="M71.5207 19.0409H70.5501L69.9173 17.6131H67.1382L66.4603 19.048H65.5442C66.3915 17.0909 67.2271 15.1891 68.0756 13.2403L68.9633 13.1934C69.8071 15.1246 70.6449 17.0393 71.5207 19.0409ZM69.5451 16.7132L68.507 14.3443C68.1277 15.2477 67.8291 15.9517 67.5091 16.7132H69.5451Z"
            fill="#FCC117"
          />
          <path
            d="M78.5911 18.2054L79.0224 17.6269C79.5309 17.844 79.9859 18.1197 80.4884 18.2382C80.9897 18.3567 81.5053 18.3614 81.9473 17.8252L81.9781 17.1483C81.8276 17.0145 81.6593 16.9007 81.4804 16.8103C80.972 16.6414 80.4636 16.5194 79.9551 16.3481C79.0924 16.0536 78.7238 15.5315 78.77 14.729C78.7925 14.3606 78.9419 14.0109 79.1931 13.7399C79.4432 13.4689 79.7797 13.2917 80.1459 13.2389C81.0573 13.0946 81.9319 13.2248 82.7366 13.8889L82.3088 14.5178C81.4745 14.208 80.6686 13.5768 79.7786 14.4063V15.2288C80.2064 15.3977 80.639 15.5667 81.0786 15.7239C81.3145 15.8084 81.5693 15.8342 81.8039 15.9198C82.6513 16.2308 83.0507 16.8303 82.9523 17.6504C82.899 18.0153 82.7248 18.3508 82.4581 18.6054C82.1915 18.86 81.8478 19.0184 81.4816 19.056C80.4446 19.1745 79.4621 19.0243 78.5911 18.2054Z"
            fill="#FCC117"
          />
          <path
            d="M2.16756 8.70801L4.34462 10.8645L2.0846 13.008L-0.11853 10.7941L2.16756 8.70801Z"
            fill="#FCC117"
          />
          <path
            d="M10.5986 8.61035L12.6844 10.6894L10.4007 12.8986L8.36707 10.8478L10.5986 8.61035Z"
            fill="#FCC117"
          />
          <path
            d="M18.655 12.949L16.5573 10.8559L18.7747 8.65723L20.832 10.7773L18.655 12.949Z"
            fill="#FCC117"
          />
          <path
            d="M60.5285 13.3843H61.4363V18.19H64.4453V19.0171H60.5273V13.3843H60.5285Z"
            fill="#FCC117"
          />
        </g>
      </g>
      <defs>
        <clipPath id={uid1}>
          <rect width="82.6948" height="20.2143" fill="white" transform="translate(0 0.892578)" />
        </clipPath>
        <clipPath id={uid2}>
          <rect width="82.6948" height="20.2143" fill="white" transform="translate(0 0.892578)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BinanceLabIcon;
