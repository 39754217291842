import Link from 'next/link';

import { ComponentPropsWithoutRef, ReactNode, useMemo } from 'react';

import { UseQueryResult } from '@tanstack/react-query';

import classNames from 'classnames';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { LaunchpadProjectListItem, LaunchpadProjectStatus } from '@api';

import { AngleLeft } from '@uikit/icons/AngleLeft';
import { AngleRight } from '@uikit/icons/AngleRight';
import { ArrowRight } from '@uikit/icons/ArrowRight';

import { LAUNCHPAD_INTERNAL_ROUTES } from '@shared/constants';

import CardProject from '@launchpad/components/common/CardProject/CardProject';
import EmptyWidget from '@launchpad/components/pages/Main/ui/widgets/EmptyWidget';
import useIsSelfTestUser from '@launchpad/hooks/useIsSelfTestUser';

export interface SliderProjectsProps extends ComponentPropsWithoutRef<'section'> {
  projectsQuery: UseQueryResult<LaunchpadProjectListItem[] | undefined, unknown>;
  title?: string;
  viewAllLabel?: string;
  showViewAll?: boolean;
  titleDecoration?: ReactNode;
  status: LaunchpadProjectStatus;
}

const SliderProjectsWidget = ({
  projectsQuery,
  title,
  className,
  titleDecoration,
  viewAllLabel = 'View All',
  showViewAll,
  status,
  ...props
}: SliderProjectsProps) => {
  const isTestWallet = useIsSelfTestUser();
  const { data: projects, isLoading } = projectsQuery;

  const hasProjects = useMemo(() => {
    // TODO: delete this after demo
    if (isTestWallet) {
      return Boolean(projects?.length);
    }

    const filteredProjects = projects?.filter((project) => project.attributes.slug !== 'demo-sale');
    return Boolean(filteredProjects?.length);
  }, [projects, isTestWallet]);

  const showArrowControls = (projects?.length || 0) > 3;

  // TODO: find better solution with skeleton

  if (!isLoading && !hasProjects) {
    return null;
  }

  return (
    <section className={classNames('w-full flex flex-col relative', className)} {...props}>
      <div className="flex justify-between">
        {title && (
          <h2 className="flex items-center gap-2 t-h3 text-[32px] h-14">
            {titleDecoration}
            {title}
          </h2>
        )}
      </div>

      {!isLoading && hasProjects && (
        <Swiper
          className={classNames('w-full my-4 md:my-12 static')}
          centeredSlides={false}
          slidesPerView="auto"
          modules={[Navigation]}
          navigation={{
            prevEl: '.prev-button',
            nextEl: '.next-button',
          }}
        >
          {showArrowControls && (
            <div className="flex justify-between gap-3 absolute top-0 right-0">
              <div className="hidden prev-button w-14 h-14 rounded-full text-neutral-50 bg-primary-700 md:flex justify-center items-center cursor-pointer">
                <AngleLeft className="icon-[24px]" />
              </div>
              <div className="hidden next-button w-14 h-14 rounded-full text-neutral-50 bg-primary-700 md:flex justify-center items-center cursor-pointer">
                <AngleRight className="icon-[24px]" />
              </div>
            </div>
          )}

          {projects?.map((project, index) => {
            {
              /* TODO: delete this after demo */
            }

            if (project.attributes.slug === 'demo-sale' && !isTestWallet) {
              return null;
            }

            return (
              <SwiperSlide
                key={project.id}
                className={classNames(
                  'select-none mx-1 md:mx-3 w-[calc(100%_-_30px)] md:w-full max-w-[340px]',
                  { 'ml-0': index === 0 },
                )}
              >
                <CardProject className="max-w-[340px]" status={status} project={project} />
              </SwiperSlide>
            );
          })}
        </Swiper>
      )}

      {!isLoading && !hasProjects && <EmptyWidget view="eye" className="my-10 h-[500px] py-0" />}

      {isLoading && (
        <div className="flex justify-start items-center my-4 md:my-12">
          <div className="h-[500px] w-full skeleton-block" />
        </div>
      )}

      {showViewAll && (
        <div className="flex justify-center">
          <Link
            href={LAUNCHPAD_INTERNAL_ROUTES.projects}
            className="ui-button ui-button-solid-primary h-12 text-sm md:text-2xl gap-2 px-4"
          >
            {viewAllLabel}
            <ArrowRight className="icon-sm" />
          </Link>
        </div>
      )}
    </section>
  );
};

export default SliderProjectsWidget;
