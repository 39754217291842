import Link from 'next/link';

import { MouseEvent } from 'react';

import ImageProxy from '@next-image-proxy';

import ExternalLink from '@uikit/components/ExternalLink/ExternalLink';
import ArrowRight from '@uikit/icons/ArrowRight';

import { LAUNCHPAD_URL } from '@shared/constants';
import { LAUNCHPAD_INTERNAL_ROUTES } from '@shared/constants';

import BinanceLabIcon from '@launchpad/assets/icons/BinanceLabIcon';
import { ReactComponent as CoinTelegraph } from '@launchpad/assets/icons/coin-telegraph.svg';
import { ReactComponent as CoinDesk } from '@launchpad/assets/icons/coindesk.svg';
import { ReactComponent as CryptoCom } from '@launchpad/assets/icons/crypto-com.svg';
import { ReactComponent as Emurgo } from '@launchpad/assets/icons/emurgo.svg';
import { ReactComponent as GSR } from '@launchpad/assets/icons/gsr.svg';
import { ReactComponent as Huobi } from '@launchpad/assets/icons/huobi.svg';
import { ReactComponent as KuCoin } from '@launchpad/assets/icons/kucoin.svg';
import { ReactComponent as MagicSquare } from '@launchpad/assets/icons/magic-square.svg';
import { ReactComponent as Republic } from '@launchpad/assets/icons/republic.svg';
import { ReactComponent as TechCrunch } from '@launchpad/assets/icons/tech-crunch.svg';
import { ReactComponent as TheBlock } from '@launchpad/assets/icons/the-block.svg';
import { useAuth } from '@launchpad/components/providers/AuthProvider/AuthProvider';
import { useAuthModal } from '@launchpad/components/ui/buttons/ConnectWalletButton';

interface LogoWithUrl {
  id: string;
  url: string;
  Icon: typeof BinanceLabIcon;
}

const partnersAndBackers: LogoWithUrl[] = [
  {
    id: 'binance-lab',
    url: 'https://labs.binance.com/',
    Icon: BinanceLabIcon,
  },
  {
    id: 'crypto-com',
    url: 'https://crypto.com/',
    Icon: CryptoCom,
  },
  {
    id: 'republic',
    url: 'https://republic.co/',
    Icon: Republic,
  },
  {
    id: 'ku-coin',
    url: 'https://www.kucoin.com/',
    Icon: KuCoin,
  },
  {
    id: 'emurgo',
    url: 'https://emurgo.io/',
    Icon: Emurgo,
  },
  {
    id: 'gsr',
    url: 'https://www.gsr.io/',
    Icon: GSR,
  },
  {
    id: 'huobi',
    url: 'https://www.huobi.com/',
    Icon: Huobi,
  },
];

const asSeenOn: LogoWithUrl[] = [
  {
    id: 'coin-desk',
    url: 'https://www.coindesk.com/business/2024/03/06/web3-app-store-magic-square-unveils-66m-grant-program/',
    Icon: CoinDesk,
  },
  {
    id: 'tech-crunch',
    url: 'https://techcrunch.com/2022/10/11/magic-square-web3-app-store-apple/',
    Icon: TechCrunch,
  },
  {
    id: 'the-block',
    url: 'https://www.theblock.co/post/278843/web3-app-store-magic-square-acquires-token-launchpad-truepnl',
    Icon: TheBlock,
  },
  {
    id: 'coin-telegraph',
    url: 'https://cointelegraph.com/press-releases/magic-square-raises-3m-seed-funding-co-led-by-binance-labs-and-republic',
    Icon: CoinTelegraph,
  },
];

const MainInfo = () => {
  const { isAuth } = useAuth();
  const { login } = useAuthModal();

  const handleProtectedAction = (event: MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
    if (!isAuth) {
      event.preventDefault();
      login();
    }
  };

  return (
    <section className="md:mt-12 md:mb-16 relative">
      <h1 className="t-h2 text-[32px] md:text-[48px]">
        Community-Driven <br /> Multi Chain Launchpad
      </h1>

      <div className="flex justify-start gap-4 py-2 sm:py-12 flex-col md:flex-row">
        <Link
          href={LAUNCHPAD_INTERNAL_ROUTES.projects}
          onClick={handleProtectedAction}
          className="ui-button ui-button-solid-primary text-2xl px-4 py-2"
        >
          Get Started <ArrowRight className="icon-sm" />
        </Link>

        <Link
          href={LAUNCHPAD_URL.howToGet}
          className="ui-button ui-button-solid-secondary text-2xl px-4 py-2"
        >
          How it Works
        </Link>
      </div>

      <div>
        <div className="flex flex-col lg:flex-row justify-start gap-2 lg:gap-4 mb-4 lg:mb-7">
          <div className="lg:min-w-[190px]">
            <p className="text-neutral-400 leading-7 border-solid lg:border-r border-neutral-400 lg:pr-4 lg:text-end">
              Partners and Backers
            </p>
          </div>

          <div className="flex gap-5 lg:max-w-[calc(100%_-_190px)] items-center overflow-auto hide-scrollbar">
            {partnersAndBackers.map(({ id, url, Icon }) => (
              <ExternalLink key={id} href={url}>
                <Icon className="h-[24px] flex-shrink-0" />
              </ExternalLink>
            ))}
          </div>
        </div>

        <div className="flex flex-col lg:flex-row justify-start gap-2 lg:gap-4 mb-4 lg:mb-7">
          <div className="lg:min-w-[190px]">
            <p className="text-neutral-400 leading-7 border-solid lg:border-r border-neutral-400 lg:pr-4 lg:text-end">
              As Seen On
            </p>
          </div>

          <div className="flex gap-5 lg:max-w-[calc(100%_-_190px)] items-center overflow-auto hide-scrollbar">
            {asSeenOn.map(({ id, url, Icon }) => (
              <ExternalLink key={id} href={url}>
                <Icon className="h-[24px] flex-shrink-0" />
              </ExternalLink>
            ))}
          </div>
        </div>

        <div className="flex justify-start items-center gap-2 lg:gap-4">
          <div className="lg:min-w-[190px]">
            <p className="text-neutral-700 leading-7 lg:text-xl font-bold border-solid lg:border-r border-neutral-400 lg:pr-4 lg:text-end">
              Powered by
            </p>
          </div>
          <div className="flex gap-5 lg:max-w-[calc(100%_-_190px)] items-center overflow-auto hide-scrollbar">
            <ExternalLink href={'https://magicsquare.io/'}>
              <MagicSquare className="min-w-[85px] h-6 lg:h-8" />
            </ExternalLink>
          </div>
        </div>
      </div>

      <ImageProxy
        src={'launchpad/main-page-image.png'}
        alt={'main-page-image'}
        className="hidden md:block absolute right-[-130px] z-[-1] top-[-100px]"
        width={460}
        height={460}
        quality={100}
      />
    </section>
  );
};

export default MainInfo;
